body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body {
    font-family: 'Vollkorn', serif;
    color: #46433A;
    background-color: #e6f2ff;
}

header,
main {
    padding: 0 20px;
}


/*** wrapper div for both header and main ***/

.wrapper {
    margin-top: 10%;
}


/*** anchor tags ***/

a:link,
a:visited,
a:hover,
a:active {
    color: #0063cc;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}


/*** main content list ***/

.main-list-item {
    font-weight: bold;
    font-size: 1.2em;
    margin: 0.8em 0;
}


/* override the left margin added by font awesome for the main content list,
since it must be aligned with the content */

.fa-ul.main-list {
    margin-left: 0;
}

.fab a, .far a {
    margin-left: 10px
}

/* list icons */

.main-list-item-icon {
    width: 36px;
    color: #46433A;
}


/*** logo ***/

.logo-container {
    text-align: center;
}

.logo {
    width: 96px;
    height: 96px;
    display: inline-block;
    background-size: cover;
    border-radius: 50%;
    -moz-border-radius: 50%;
    border: 2px solid #e6f2ff;
    box-shadow: 0 0 0 3px #46433A;
}


/*** author ***/

.author-container h1 {
    font-size: 2.8em;
    margin-top: 0;
    margin-bottom: 0;
    text-align: right;
    color: rgb(45, 91, 143);
}

/*** tagline ***/
.tagline-container p {
    font-size: 1.3em;
    text-align: center;
    margin-bottom: 2em;
}


/******/

hr {
    border: 0;
    height: 1px;
    background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0), #46433A, rgba(0, 0, 0, 0));
}


/*** footer ***/

footer {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 20px;
}

.poweredby {
    font-family: "Arial Narrow", Arial;
    font-size: 0.6em;
    line-height: 0.6em;
    padding: 0 5px;
}


/*** media queries ***/


/* X-Small devices (phones, 480px and up) */

@media (min-width: 480px) {
    /* wrapper stays 480px wide past 480px wide and is kept centered */
    .wrapper {
        width: 480px;
        margin: 10% auto 0 auto;
    }
}

.author-container h1,
.tagline-container p {
    text-align: center;
}
/* All other devices (768px and up) */

@media (min-width: 768px) {
    /* past 768px the layout is changed and the wrapper has a fixed width of 680px
  to accomodate both the header column and the content column */
    .wrapper {
        width: 680px;
    }
    /* the header column stays left and has a dynamic width with all contents
  aligned right */
    header {
        float: left;
        width: 46%;
        text-align: right;
    }

    .logo-container{
        text-align: right;
    }

    main {
        width: 46%;
        margin-left: 54%;
        padding: 0;
    }
}
